import axios from 'axios';
import {
  TOPUPS_USERTOPUPS_FAIL,
  TOPUPS_USERTOPUPS_REQUEST,
  TOPUPS_USERTOPUPS_SUCCESS,
} from '../constants/topUpConstants';
import {
  TOPUP_DETAILS_FAIL,
  TOPUP_DETAILS_SUCCESS,
  TOPUP_DETAILS_REQUEST,
  TOPUP_LIST_FAIL,
  TOPUP_LIST_SUCCESS,
  TOPUP_LIST_REQUEST,
  TOPUP_UPDATE_REQUEST,
  TOPUP_UPDATE_SUCCESS,
  TOPUP_UPDATE_FAIL,
} from '../constants/topUpConstants';
import { logout } from './userActions';

const local = 'https://api.barapp.nl';
// const local = 'http://localhost:5000';
export const getTopUpDetails = (id) => async (dispatch, getState) => {
  console.log('action ', id);
  try {
    dispatch({
      type: TOPUP_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${local}/api/topup/${id}`, config);

    dispatch({
      type: TOPUP_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: TOPUP_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateTopup = (topUpId, isPaid) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOPUP_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${local}/api/topup/${topUpId}/update`,
      isPaid,
      config
    );

    dispatch({
      type: TOPUP_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: TOPUP_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const listTopUps = (orgname) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOPUP_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${local}/api/topup/list/${orgname}`,
      config
    );

    dispatch({
      type: TOPUP_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: TOPUP_LIST_FAIL,
      payload: message,
    });
  }
};

export const getUserTopUps = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOPUPS_USERTOPUPS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${local}/api/topup/usertopups/${user}`,
      config
    );
    dispatch({
      type: TOPUPS_USERTOPUPS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (
      message === 'Verkeerde token, niet hacken he' ||
      message === 'Je hebt geen password token'
    ) {
      dispatch(logout());
    }
    dispatch({
      type: TOPUPS_USERTOPUPS_FAIL,
      payload: message,
    });
  }
};
