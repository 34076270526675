import React, { useEffect, useState } from 'react';
import { listUsers, deleteUser } from '../../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../components/Loader';
import Nav from '../../components/Nav';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/outline';
import User from '../../components/User';
import { USER_DETAILS_RESET } from '../../constants/userConstants';
import { ORDER_USERORDERS_RESET } from '../../constants/orderConstants';
import { TOPUPS_USERTOPUPS_RESET } from '../../constants/topUpConstants';
const UserList = ({ history }) => {
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete } = userDelete;

  useEffect(() => {
    dispatch({ type: USER_DETAILS_RESET });
    dispatch({ type: ORDER_USERORDERS_RESET });
    dispatch({ type: TOPUPS_USERTOPUPS_RESET });

    if (userInfo && userInfo.isAdmin) {
      dispatch(listUsers(userInfo.organisation));
    } else {
      history.push('/inloggen');
    }
    // eslint-disable-next-line
  }, [dispatch, history, successDelete, userInfo]);
  const addUserHandler = () => {
    history.push('/gebruikers/aanmaken');
  };
  return (
    <div className='h-[100vh] overflow-hidden'>
      <Nav />
      {loading ? (
        <Loader />
      ) : (
        <div className='flex h-[80%] justify-center items-center'>
          <div className=' h-full md:h-[80%] lg:h-[35vh] overflow-y-auto overflow-x-hidden'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-center h-full w-[80vw]'>
              <div className=' col-span-full justify-self-start w-[80%]'>
                <div
                  className='w-80 h-12 bg-[#eee] rounded-md grid place-items-center text-black cursor-pointer'
                  onClick={addUserHandler}
                >
                  <h3 className='inline font-bold'>
                    <PlusIcon className='inline w-7 mr-2' /> Gebruiker toevoegen
                  </h3>
                </div>
              </div>
              {users.map((user) => (
                <>
                  {' '}
                  {user.organisation === userInfo.organisation ? (
                    <User user={user} />
                  ) : (
                    ''
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserList;
