import { Link } from 'react-router-dom';

import { login } from '../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import Alert from '../components/Alert';
const Login = ({ history, location }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    if (userInfo && userInfo?.isAdmin) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };
  return (
    <div className='h-[100vh] relative overflow-hidden'>
      <div className='h-max block  place-items-center lg:grid lg:grid-cols-2 align-center overflow-hidden'>
        <section className='hidden login  h-[65vh] w-[45vw] xl:h-[90vh] xl:w-[40vw] lg:grid place-content-center bg-[#10B981] text-white text-8xl font-bold text-center'>
          Inloggen
        </section>
        <div className='flex space-x-5 p-5 lg:hidden'>
          <h1 className=' text-white z-10 font-bold text-3xl sm:text-5xl'>
            Barapp
          </h1>
          <div className='-z-20 w-[100vw] absolute top-[-5rem] sm:top-[-4rem] left-[-2rem] sm:right-[-4rem] scale-[125%] md:scale-[200%]  '>
            <svg
              className='md:w-full'
              xmlns='http://www.w3.org/2000/svg'
              width='584.636'
              height='190.675'
              viewBox='0 0 584.636 190.675'
            >
              <path
                id='blob'
                d='M-11.853-9.754C-70.89,19.8-74.338,112.025-17.024,143.651c57.314,31.922,174.957,3.251,248.215-14.483,72.827-17.735,100.406-25.124,159.444-57.342,58.606-32.514,148.24-90.151,134.45-101.975C510.864-41.972,393.651-7.98,277.731-7.389,161.38-6.8,47.184-39.312-11.853-9.754Z'
                transform='translate(58.13 32.644)'
                fill='#34d399'
              />
            </svg>
          </div>
        </div>
        <section className='flex flex-col justify-center align-middle my-auto md:grid place-content-center w-full h-[100vh]  '>
          {error && <Alert message={error} />}
          <form action='post' className=' block mx-auto w-[90vw] md:w-[30vw]'>
            <h1 className=' font-bold text-5xl mb-5 text-center'>
              Welkom terug
            </h1>
            <label className='block'>
              <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block  font-medium text-gray-700 text-lg">
                Email
              </span>
              <input
                type='email'
                name='email'
                className='mt-1  px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] block w-full rounded sm:text-sm focus:ring-1'
                placeholder='jij@voorbeeld.nl'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </label>
            <label className='block mt-5'>
              <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block  font-medium text-gray-700 text-lg">
                Wachtwoord
              </span>
              <input
                type='password'
                name='password'
                className='mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] block w-full rounded sm:text-sm focus:ring-1'
                placeholder='Ik hoop dat je deze nog weet'
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </label>
            <div className='flex items center space-x-6 max-w-lg mx-auto mt-10'>
              <button
                type='submit'
                className='appearance-none block rounded bg-[#10B981] w-[30rem] align-middle text-center py-3 px-4 leading-tight text-white font-bold'
                onClick={submitHandler}
              >
                Inloggen
              </button>
            </div>
          </form>
          <h3 className='mt-2 text-center'>
            Geen account? Vraag even de barman om eentje aan te maken
          </h3>
        </section>
      </div>
    </div>
  );
};

export default Login;
